const state = {
  snackbar: {
    message: '',
    type: '',
    active: false
  }
}

const actions = {
  setSnackbar({ commit }, payload) {
    commit('SET_SNACKBAR', payload)
  }
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_SNACKBAR(state, payload) {
    state.snackbar = payload
  }
}

const getters = {
  // eslint-disable-next-line no-shadow
  snackbar: state => state.snackbar
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

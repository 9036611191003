import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuthDecode } from '@/utils/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'track-list'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue')
  },
  {
    path: '/add',
    name: 'add',
    component: () => import('@/views/track/Add.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import('@/views/track/Upload.vue')
  },
  {
    path: '/:id/detail',
    name: 'detail',
    component: () => import('@/views/track/Detail.vue')
  },
  {
    path: '/:id/update',
    name: 'update',
    component: () => import('@/views/track/Add.vue')
  },
  {
    path: '/track-list',
    name: 'trackList',
    component: () => import('@/views/track/TrackList.vue')
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue')
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue')
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue')
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue')
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue')
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue')
  },
  {
    path: '/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/logout',
    name: 'pages-logout',
    component: () => import('@/views/pages/Logout.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '*',
    redirect: 'error-404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const publicRoute = [
  'pages-login',
  'pages-logout'
]

router.beforeEach((to, from, next) => {
  const routeName = to.name
  if (publicRoute.some(pr => pr === routeName)) {
    next()
  } else {
    const data = getAuthDecode()
    if (data && ((data.exp - 1800) * 1000) <= new Date().getTime()) {
      router.replace({ name: 'pages-login' })
    } else if (data === null) {
      router.replace({ name: 'pages-login' })
    }
  }
  next()
})

export default router
